import {useState} from 'react';
import {Button, Checkbox, FormControl} from '@job-ish/ui/components';
import {IconTrash} from '@tabler/icons-react';

import type {CheckedState} from '@job-ish/ui/components';

type DeleteAccountFormProps = {
	onSubmit: () => void;
	onCancel: () => void;
};

export const DeleteAccountForm = ({onCancel, onSubmit}: DeleteAccountFormProps) => {
	const [checked, setChecked] = useState<CheckedState>(false);

	return (
		<div className="mt-4 flex h-full flex-col items-start">
			<FormControl inlineLabel label="Confirm you want to delete your account">
				<Checkbox checked={checked} onCheckedChange={setChecked} />
			</FormControl>
			<div className="mt-auto flex w-full items-center justify-end gap-3 p-2 pt-4">
				<Button intent="ghost" onPress={onCancel} type="button">
					Cancel
				</Button>
				<Button color="danger" disabled={!checked} iconRight={IconTrash} onPress={onSubmit}>
					Delete Account
				</Button>
			</div>
		</div>
	);
};
