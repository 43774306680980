import {DeleteJobSearchForm} from '~/shared/components/forms/job-search/delete';
import {useDeleteJobSearch} from '~/shared/data/job-search';
import {AlertDialog} from '@job-ish/ui/components';

import type {JobSearch} from '@job-ish/database/types';
import type {ReactNode} from 'react';

export type DeleteJobSearchAlertProps = {
	jobSearch: JobSearch;
	open: boolean;
	onOpenChange: (open: boolean) => void;
	trigger?: ReactNode;
};

export const DeleteJobSearchAlert = ({jobSearch, open, onOpenChange, trigger}: DeleteJobSearchAlertProps) => {
	const {mutate: deleteJobSearch} = useDeleteJobSearch();

	const onSubmit = async () => {
		onOpenChange(false);
		deleteJobSearch({id: jobSearch.id});
	};

	return (
		<AlertDialog onOpenChange={onOpenChange} open={open}>
			<AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
			<AlertDialog.Content className="md:w-[28rem]">
				<AlertDialog.Title>Delete Job Search</AlertDialog.Title>
				<AlertDialog.Description>
					Are you sure you want to delete <span className="font-bold">{jobSearch.name}</span>? This action
					cannot be undone.
				</AlertDialog.Description>
				<DeleteJobSearchForm onCancel={() => onOpenChange(false)} onSubmit={onSubmit} />
			</AlertDialog.Content>
		</AlertDialog>
	);
};
