import {useCallback, useMemo, useState} from 'react';
import {
	DeleteJobSearchAlert,
	type DeleteJobSearchAlertProps,
} from '~/shared/components/alerts/delete-job-search';
import type {JobSearchDialogProps} from '~/shared/components/dialogs/job-search';
import {JobSearchDialog} from '~/shared/components/dialogs/job-search';
import {useActiveJobSearch, useJobSearches, useUpdateActiveJobSearch} from '~/shared/data/job-search';
import {useCoreStore} from '~/shared/stores/use-core-store';
import {ButtonGroup, Combobox, IconButton, Overlay, Popover, Select} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconCirclePlus, IconPencil, IconTrash} from '@tabler/icons-react';

import type {JobSearch} from '@job-ish/database/types';

export const JobSearchSelect = () => {
	const [open, setOpen] = useState(false);
	const {data: jobSearches} = useJobSearches();
	const {data: activeJobSearch} = useActiveJobSearch();
	const {mutate: updateActiveJobSearch} = useUpdateActiveJobSearch();
	const {loading} = useCoreStore();

	const {show} = useModal();

	const showDialog: typeof show = useCallback(
		(...params) => {
			setOpen(false);
			show(...params);
		},
		[show],
	);

	const onValueChange = useCallback(
		async (jobSearchId?: string | null) => {
			if (!jobSearchId) return;
			updateActiveJobSearch({id: Number(jobSearchId)});
		},
		[updateActiveJobSearch],
	);

	const value = useMemo(
		() => (jobSearches && jobSearches.length > 0 ? (activeJobSearch ?? jobSearches[0]) : undefined),
		[activeJobSearch, jobSearches],
	);

	const jobSearchesByStatus = useMemo(
		() =>
			jobSearches?.reduce<{active: JobSearch[]; completed: JobSearch[]}>(
				(acc, jobSearch) => {
					acc[jobSearch.status as 'active' | 'completed'].push(jobSearch);
					return acc;
				},
				{active: [], completed: []},
			),
		[jobSearches],
	);

	const groupedJobSearches = useMemo(
		() =>
			[
				{
					label: 'Active Searches',
					options: jobSearchesByStatus?.active.map(({id, name}) => ({label: name, value: id})),
				},
				{
					label: 'Completed Searches',
					options: jobSearchesByStatus?.completed.map(({id, name}) => ({label: name, value: id})),
				},
			].filter(({options}) => options && options.length > 0),
		[jobSearchesByStatus?.active, jobSearchesByStatus?.completed],
	);

	const handleValueChange = useCallback(
		(newValue?: string) => {
			setOpen(false);
			if (newValue === value?.id.toString()) return;
			onValueChange(newValue === undefined ? null : newValue);
		},
		[onValueChange, value],
	);

	return (
		<Popover onOpenChange={setOpen} open={open}>
			<Popover.Trigger className="w-fit overflow-hidden rounded-md">
				<Select value={value?.id?.toString() ?? undefined}>
					<Select.Trigger
						asChild
						className="max-h-11 w-full flex-shrink flex-grow overflow-hidden px-2 [&>div]:text-mauve11"
						subtle
					>
						{value?.name}
					</Select.Trigger>
				</Select>
			</Popover.Trigger>
			<Popover.Content
				align="start"
				className="z-[60] flex w-72 flex-col items-start gap-3"
				padding="md"
				side="bottom"
				sideOffset={8}
			>
				{loading && <Overlay loading />}
				<ButtonGroup className="w-full">
					<Combobox>
						<Combobox.Trigger className="w-full min-w-0 shrink overflow-hidden">
							<Select value={value?.id?.toString() ?? undefined}>
								<Select.Trigger asChild className="flex-grow rounded-[inherit]" size="sm">
									{value?.name}
								</Select.Trigger>
							</Select>
						</Combobox.Trigger>
						<Combobox.Content align="start" className="z-[60] w-64" side="bottom">
							<Combobox.List>
								<Combobox.Group>
									<Combobox.Item
										icon={IconCirclePlus}
										onSelect={() => showDialog<JobSearchDialogProps>(JobSearchDialog)}
										tabIndex={0}
									>
										New Job Search
									</Combobox.Item>
									<Combobox.Separator />
								</Combobox.Group>
								{groupedJobSearches.map(({options, label}, index) => (
									<Combobox.Group key={label}>
										<Combobox.Label>{label}</Combobox.Label>
										{options?.map(option => (
											<Combobox.Item
												checked={value?.id === option.value}
												className="w-full overflow-hidden"
												key={option.value}
												onSelect={handleValueChange}
												value={option.value.toString()}
											>
												<div className="truncate">{option.label}</div>
											</Combobox.Item>
										))}
										{index !== groupedJobSearches.length - 1 && <Combobox.Separator />}
									</Combobox.Group>
								))}
							</Combobox.List>
						</Combobox.Content>
					</Combobox>
					{value && (
						<>
							<IconButton
								icon={IconPencil}
								onPress={() => showDialog<JobSearchDialogProps>(JobSearchDialog, {jobSearch: value})}
								size="sm"
							/>
							{jobSearches && jobSearches.length > 1 && (
								<IconButton
									color="danger"
									icon={IconTrash}
									onPress={() =>
										showDialog<DeleteJobSearchAlertProps>(DeleteJobSearchAlert, {jobSearch: value})
									}
									size="sm"
								/>
							)}
						</>
					)}
				</ButtonGroup>
			</Popover.Content>
		</Popover>
	);
};
