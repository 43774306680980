import {PATH_LABELS, PATHS} from '~/shared/constants/paths';
import {NavLinks, Tooltip} from '@job-ish/ui/components';
import {IconFiles, IconGraph, IconIdBadge2, IconLayoutKanban, IconListDetails} from '@tabler/icons-react';
import NextLink from 'next/link';
import {usePathname} from 'next/navigation';

export type NavigationListProps = {
	iconsOnly?: boolean;
};

const NAVIGATION_LIST_ITEMS = [
	{href: PATHS.JobBoard, icon: IconListDetails, label: PATH_LABELS[PATHS.JobBoard]},
	{href: PATHS.Dashboard, icon: IconLayoutKanban, label: PATH_LABELS[PATHS.Dashboard]},
	{href: PATHS.Analytics, icon: IconGraph, label: PATH_LABELS[PATHS.Analytics]},
	{href: PATHS.Resumes, icon: IconFiles, label: PATH_LABELS[PATHS.Resumes]},
	{href: PATHS.PersonalPage, icon: IconIdBadge2, label: PATH_LABELS[PATHS.PersonalPage]},
] as const;

export const NavigationList = ({iconsOnly}: NavigationListProps) => {
	const pathname = usePathname();

	return (
		<NavLinks className="w-full">
			{NAVIGATION_LIST_ITEMS.map(({href, icon, label}) => (
				<Tooltip key={href}>
					<Tooltip.Trigger asChild>
						<NavLinks.Item
							aria-label={label}
							as={NextLink}
							centered={iconsOnly}
							current={pathname.startsWith(href) || pathname === href}
							href={href}
							icon={icon}
						>
							{iconsOnly ? null : label}
						</NavLinks.Item>
					</Tooltip.Trigger>
					<Tooltip.Content className="z-50" side="right" sideOffset={4}>
						{label}
					</Tooltip.Content>
				</Tooltip>
			))}
		</NavLinks>
	);
};
