import {useCallback} from 'react';
import {DeleteAccountForm} from '~/shared/components/forms/account/delete';
import {useDeleteAccount} from '~/shared/data/account';
import {AlertDialog, Overlay} from '@job-ish/ui/components';

export type DeleteAccountAlertProps = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
};

export const DeleteAccountAlert = ({open, onOpenChange}: DeleteAccountAlertProps) => {
	const {mutateAsync: deleteAccount, isPending} = useDeleteAccount();

	const handleSubmit = useCallback(async () => {
		await deleteAccount();
		onOpenChange(false);
	}, [deleteAccount, onOpenChange]);

	return (
		<AlertDialog onOpenChange={onOpenChange} open={open}>
			<AlertDialog.Content className="md:w-[28rem]">
				{isPending && <Overlay loading />}
				<AlertDialog.Title>Delete Account</AlertDialog.Title>
				<AlertDialog.Description>
					Are you sure you want to delete your account and cancel any active subscriptions? This action cannot
					be undone.
				</AlertDialog.Description>
				<DeleteAccountForm onCancel={() => onOpenChange(false)} onSubmit={handleSubmit} />
			</AlertDialog.Content>
		</AlertDialog>
	);
};
