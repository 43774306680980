import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';

export type KbdProps = ComponentPropsWithoutRef<'span'>;
const Kbd = forwardRef<HTMLSpanElement, KbdProps>(({className, ...props}, forwardedRef) => (
	<kbd
		{...props}
		className={twMerge(
			'inline-flex items-center justify-center rounded-sm border-2 border-b-4 border-mauve9 bg-mauve7 px-1 py-0.5 font-mono text-xs font-medium tracking-widest text-mauve12',
			className,
		)}
		ref={forwardedRef}
	/>
));

Kbd.displayName = 'Kbd';

export default Kbd;
