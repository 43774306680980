'use client';

import {fetchActiveJobSearch, fetchJobSearches} from '~/shared/api/job-search';
import {JOB_SEARCH_STATUS} from '~/shared/constants/job-search';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useCoreStore} from '~/shared/stores/use-core-store';
import {useToast} from '@job-ish/ui/hooks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useRouter} from 'next/navigation';

import type {JobSearch} from '@job-ish/database/types';
import type {SetRequired} from 'type-fest';

export const useJobSearches = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.JobSearches,
		queryFn: async () => {
			const {data} = await fetchJobSearches(supabase);
			return data;
		},
	});
};

export const useActiveJobSearch = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.ActiveJobSearch,
		queryFn: async () => {
			const {data} = await fetchActiveJobSearch(supabase);
			return data;
		},
	});
};

export const useUpdateActiveJobSearch = () => {
	const queryClient = useQueryClient();
	const {setLoading} = useCoreStore();
	const router = useRouter();
	const {supabase} = useSupabase();

	return useMutation({
		mutationFn: async ({id}: {id: number}) => {
			const {error} = await supabase
				.from('job_searches')
				.update({is_active: false})
				.match({is_active: true})
				.returns<JobSearch[]>();

			if (!error) {
				const {data} = await supabase
					.from('job_searches')
					.update({is_active: true})
					.match({id})
					.returns<JobSearch[]>();

				return data;
			}
		},
		onMutate: async ({id}: {id: number}) => {
			setLoading(true);
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.ActiveJobSearch});
			const previousValue = queryClient.getQueryData(QUERY_KEYS.ActiveJobSearch);

			const jobSearch = queryClient
				.getQueryData<JobSearch[]>(QUERY_KEYS.JobSearches)
				?.find((jobSearch: JobSearch) => jobSearch.id === id);

			queryClient.setQueryData(QUERY_KEYS.ActiveJobSearch, jobSearch);
			return previousValue;
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.ActiveJobSearch, context);
		},
		onSettled: async () => {
			router.refresh();
			await queryClient.invalidateQueries();
		},
	});
};

export const useDeleteJobSearch = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();
	const router = useRouter();
	const {setLoading} = useCoreStore();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Job Search could not be deleted. Please try again.',
		duration: 1500,
		title: 'Job Search Deletion Failed',
	});

	return useMutation({
		mutationFn: async ({id}: {id: number}) => {
			const {data, error} = await supabase.from('job_searches').delete().match({id}).returns<JobSearch[]>();

			if (!error) {
				await supabase
					.from('job_searches')
					.update({is_active: true})
					.order('id', {ascending: true})
					.limit(1)
					.returns<JobSearch[]>();
			}

			if (error) throw error;
			return data;
		},
		onMutate: async ({id}: {id: number}) => {
			setLoading(true);
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.JobSearches});
			const previousJobSearches = queryClient.getQueryData<JobSearch[]>(QUERY_KEYS.JobSearches) ?? [];
			const activeJobSearch = queryClient.getQueryData<JobSearch>(QUERY_KEYS.ActiveJobSearch);

			queryClient.setQueryData(
				QUERY_KEYS.JobSearches,
				previousJobSearches.filter(jobSearch => jobSearch.id !== id),
			);
			queryClient.setQueryData(
				QUERY_KEYS.ActiveJobSearch,
				activeJobSearch?.id === id ? null : activeJobSearch,
			);
			queryClient.setQueryData(
				QUERY_KEYS.ActiveJobSearch,
				previousJobSearches.find(jobSearch => jobSearch.id !== id),
			);

			return {previousJobSearches, activeJobSearch};
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.JobSearches, context?.previousJobSearches);
			queryClient.setQueryData(QUERY_KEYS.ActiveJobSearch, context?.activeJobSearch);
			showErrorToast();
		},
		onSettled: async () => {
			router.refresh();
			await queryClient.invalidateQueries();
		},
	});
};

export const useUpsertJobSearch = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();
	const {setLoading} = useCoreStore();
	const router = useRouter();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Job search could not be updated. Please try again.',
		duration: 1500,
		title: 'Job Search Update Failed',
	});

	return useMutation({
		mutationFn: async (data: SetRequired<Partial<JobSearch>, 'name'>) => {
			const {data: jobSearch, error} = await supabase
				.from('job_searches')
				.upsert(
					{
						...data,
						is_active: true,
						completed_at: data.status === JOB_SEARCH_STATUS.Active ? null : data.completed_at,
					},
					{onConflict: 'id'},
				)
				.select('*')
				.maybeSingle<JobSearch>();
			if (error) throw error;

			if (jobSearch) {
				await supabase
					.from('job_searches')
					.update({is_active: false})
					.neq('id', jobSearch.id)
					.returns<JobSearch[]>();
			}

			return jobSearch;
		},
		onMutate: async (data: SetRequired<Partial<JobSearch>, 'name'>) => {
			setLoading(true);
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.JobSearches});
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.ActiveJobSearch});
			const previousJobSearches = queryClient.getQueryData<JobSearch[]>(QUERY_KEYS.JobSearches) ?? [];
			const previousActiveJobSearch = queryClient.getQueryData<JobSearch>(QUERY_KEYS.ActiveJobSearch);

			const jobSearch = previousJobSearches.find(jobSearch => jobSearch.id === data.id);
			const updatedJobSearches = jobSearch
				? previousJobSearches.map(jobSearch =>
						jobSearch.id === data.id ? {...jobSearch, ...data} : jobSearch,
					)
				: [...previousJobSearches, {...data, id: -1} as JobSearch];

			queryClient.setQueryData(QUERY_KEYS.JobSearches, updatedJobSearches);
			queryClient.setQueryData(QUERY_KEYS.ActiveJobSearch, {...previousActiveJobSearch, ...data});
			return {previousJobSearches, previousActiveJobSearch};
		},
		onError: (_error, {id}, context) => {
			queryClient.setQueryData(QUERY_KEYS.JobSearches, context?.previousJobSearches);
			queryClient.setQueryData(QUERY_KEYS.ActiveJobSearch, context?.previousActiveJobSearch);
			showErrorToast(
				id
					? undefined
					: {
							description: 'Job search could not be created. Please try again.',
							title: 'Job Search Creation Failed',
						},
			);
		},
		onSettled: async () => {
			router.refresh();
			await queryClient.invalidateQueries();
		},
	});
};
