import {type ReactNode, useCallback} from 'react';
import {UpsertJobSearchForm} from '~/shared/components/forms/job-search/upsert';
import {useUpsertJobSearch} from '~/shared/data/job-search';
import type {UpsertJobSearchFormSchema} from '~/shared/schemas/job-search';
import {convertToTimestampz} from '~/shared/utils/time';
import {Dialog} from '@job-ish/ui/components';
import dayjs from 'dayjs';

import type {JobSearch} from '@job-ish/database/types';
import type {SubmitHandler} from 'react-hook-form';

export type JobSearchDialogProps = {
	trigger?: ReactNode;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	jobSearch?: JobSearch;
};

export const JobSearchDialog = ({trigger, open, onOpenChange, jobSearch}: JobSearchDialogProps) => {
	const {mutate: upsertJobSearch} = useUpsertJobSearch();

	const handleJobSearchUpsert: SubmitHandler<UpsertJobSearchFormSchema> = useCallback(
		async formData => {
			if (jobSearch?.status !== 'completed' && formData.status === 'completed') {
				upsertJobSearch({
					...formData,
					id: jobSearch?.id,
					is_active: true,
					completed_at: convertToTimestampz(dayjs().unix()),
				});
			} else {
				upsertJobSearch({...formData, id: jobSearch?.id, is_active: true});
			}

			onOpenChange?.(false);
		},
		[jobSearch?.id, jobSearch?.status, onOpenChange, upsertJobSearch],
	);

	return (
		<Dialog onOpenChange={onOpenChange} open={open}>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Content>
				<Dialog.Title>{jobSearch ? 'Update' : 'Create'} Job Search</Dialog.Title>
				{!jobSearch && (
					<Dialog.Description>
						Get started by giving a name to your job search. You can always change it later.
					</Dialog.Description>
				)}
				<UpsertJobSearchForm jobSearch={jobSearch} onSubmit={handleJobSearchUpsert} />
			</Dialog.Content>
		</Dialog>
	);
};
