import {useMemo, useState} from 'react';
import type {ResumeUploadDialogProps} from '~/shared/components/dialogs/resume-upload';
import {ResumeUploadDialog} from '~/shared/components/dialogs/resume-upload';
import type {JobSheetProps} from '~/shared/components/sheets/job';
import {JobSheet} from '~/shared/components/sheets/job';
import {PATHS} from '~/shared/constants/paths';
import {useActiveJobSearch} from '~/shared/data/job-search';
import {useSubscription} from '~/shared/data/subscription';
import {usePersistedColorMode} from '~/shared/hooks/use-persisted-color-mode';
import {getPathLabel} from '~/shared/utils/path';
import {Logo} from '@job-ish/blocks/components';
import {DropdownMenu, IconButton} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconCirclePlus, IconFilePlus, IconSlash, IconTransferIn} from '@tabler/icons-react';
import clsx from 'clsx';
import {usePathname} from 'next/navigation';

import {FederatedSearch} from './federated-search';
import {JobSearchSelect} from './job-search-select';
import {UserDropdown} from './user-dropdown';

export type HeaderProps = {
	sidebarOpen?: boolean;
};

export const Header = ({sidebarOpen}: HeaderProps) => {
	const pathname = usePathname();
	const {data: jobSearch} = useActiveJobSearch();
	const {data: subscription} = useSubscription();
	const [createDropdownOpen, setCreateDropdownOpen] = useState(false);
	const colorMode = usePersistedColorMode();

	const pathLabel = useMemo(() => getPathLabel(pathname), [pathname]);
	const subscribePathActive = pathname === PATHS.Subscribe;

	const subscriptionActive = useMemo(() => subscription?.status === 'active', [subscription]);

	const {show: showDialog} = useModal();

	return (
		<header
			className={clsx(
				'flex h-14 w-full grow items-center justify-between border-b border-mauve6 py-2 text-mauve12',
				sidebarOpen ? 'px-4' : 'pr-4',
				subscribePathActive && 'sm:px-4',
			)}
		>
			{subscriptionActive ? (
				<div className="flex h-11 min-w-0 shrink items-center overflow-x-hidden px-0.5 font-semibold sm:text-lg">
					{!subscribePathActive && jobSearch && (
						<>
							<JobSearchSelect />
							<IconSlash className="mr-2 hidden h-4 w-4 flex-shrink-0 text-mauve11 sm:flex" />
						</>
					)}
					{pathLabel && <span className="hidden text-base text-mauve12 sm:flex">{pathLabel}</span>}
				</div>
			) : (
				<a
					className={clsx('z-50 w-[180px]', subscribePathActive ? 'px-0' : 'px-4')}
					href="https://job-ish.com"
					tabIndex={-1}
					target="_blank"
				>
					<div className="flex w-fit items-center justify-center gap-2 sm:justify-start">
						<Logo colorMode={colorMode} />
					</div>
				</a>
			)}
			<div
				className={clsx(
					'flex grow items-center justify-end gap-2 pl-2 md:grow-0',
					!subscriptionActive && 'w-full',
				)}
			>
				{!subscribePathActive && subscriptionActive && <FederatedSearch />}
				{jobSearch && !subscribePathActive && subscriptionActive && (
					<DropdownMenu onOpenChange={setCreateDropdownOpen} open={createDropdownOpen}>
						<DropdownMenu.Trigger
							asChild
							className="rounded-full p-1 focus-visible:outline focus-visible:outline-1 focus-visible:outline-violet8"
						>
							<IconButton
								aria-label="Open Add Dropdown Menu"
								circular
								color="primary"
								icon={IconCirclePlus}
								intent="subtle"
								onPress={() => setCreateDropdownOpen(true)}
							/>
						</DropdownMenu.Trigger>
						<DropdownMenu.Content align="end" className="z-10 w-48">
							<DropdownMenu.Item icon={IconTransferIn} onSelect={() => showDialog<JobSheetProps>(JobSheet)}>
								Track Application
							</DropdownMenu.Item>
							<DropdownMenu.Item
								icon={IconFilePlus}
								onSelect={() => showDialog<ResumeUploadDialogProps>(ResumeUploadDialog)}
							>
								Upload Resume
							</DropdownMenu.Item>
						</DropdownMenu.Content>
					</DropdownMenu>
				)}
				<UserDropdown />
			</div>
		</header>
	);
};
