import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {FormInput} from '~/shared/components/inputs/form-input';
import {jobSearchStatuses} from '~/shared/constants/job-search';
import type {UpsertJobSearchFormSchema} from '~/shared/schemas/job-search';
import {getDefaultUpsertJobSearchFormValues, upsertJobSearchSchema} from '~/shared/schemas/job-search';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, Select, TextButton, TextField} from '@job-ish/ui/components';

import type {JobSearch} from '@job-ish/database/types';
import type {FormEventHandler} from 'react';
import type {SubmitHandler} from 'react-hook-form';

type UpsertJobSearchFormProps = {
	jobSearch?: JobSearch;
	onSubmit: SubmitHandler<UpsertJobSearchFormSchema>;
};

export const UpsertJobSearchForm = ({jobSearch, onSubmit}: UpsertJobSearchFormProps) => {
	const {control, handleSubmit, reset, setValue} = useForm<UpsertJobSearchFormSchema>({
		resolver: zodResolver(upsertJobSearchSchema),
		defaultValues: getDefaultUpsertJobSearchFormValues(jobSearch),
	});

	const submitForm: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();
		event.stopPropagation();
		await handleSubmit(onSubmit)();
	};

	const resetForm: FormEventHandler<HTMLFormElement> = event => {
		event.preventDefault();
		event.stopPropagation();
		reset();
	};

	const jobSearchStatusLabel = useCallback(
		(value: string) => jobSearchStatuses.find(status => status.value === value)?.label,
		[],
	);

	return (
		<form
			className="flex h-full w-full grow flex-col overflow-hidden"
			onReset={resetForm}
			onSubmit={submitForm}
		>
			<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-y-auto p-2">
				<div className="flex h-fit grow flex-col gap-2">
					<FormInput
						control={control}
						label="Job Search Name"
						name="name"
						render={field => <TextField {...field} placeholder="Enter a job search name" />}
						required
					/>

					{jobSearch && (
						<FormInput
							control={control}
							label="Job Search Status"
							name="status"
							render={field => (
								<Select
									onValueChange={value =>
										setValue('status', value as UpsertJobSearchFormSchema['status'], {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
									value={field.value || undefined}
								>
									<Select.Trigger {...field} className="w-full">
										{jobSearchStatusLabel(field.value)}
									</Select.Trigger>
									<Select.Content className="z-50 w-64">
										<Select.Group>
											<Select.Label>Status</Select.Label>
											<Select.Item value="active">Active</Select.Item>
											<Select.Item value="completed">Completed</Select.Item>
										</Select.Group>
									</Select.Content>
								</Select>
							)}
						/>
					)}
				</div>
			</div>
			<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
				{jobSearch && <TextButton type="reset">Reset</TextButton>}
				<Button color="primary" type="submit">
					{jobSearch ? 'Update' : 'Create'} Job Search
				</Button>
			</div>
		</form>
	);
};
