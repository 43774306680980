import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const navLinksStyles = cva('flex flex-col [&>*]:gap-1');
export type NavLinksVariantProps = VariantProps<typeof navLinksStyles>;

export const navLinksItemStyles = cva(
	[
		'w-full flex items-center gap-3 py-3 px-4 text-sm font-medium tracking-wider',
		'hover:no-underline text-mauve12 visited:text-mauve12 rounded-md',
		'outline-none focus-visible:outline-1 focus-visible:outline-mauve8',
	],
	{
		variants: {
			current: {true: 'bg-mauve5', false: 'hover:bg-mauve4'},
			disabled: {true: 'opacity-40 pointer-events-none', false: ''},
			centered: {true: 'justify-center', false: ''},
		},
		defaultVariants: {current: false, disabled: false, centered: false},
	},
);
export type NavLinksItemVariantProps = VariantProps<typeof navLinksItemStyles>;
