import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const checkboxStyles = cva(
	[
		'h-5 w-5 rounded-sm border border-mauve7 flex items-center justify-center transition-colors ease-in-out',
		'focus-visible:outline-mauve8 outline-none select-none',
	],
	{
		variants: {
			checked: {
				true: 'bg-violet9 text-mauve1 dark:text-mauve12',
				indeterminate: 'bg-violet9 text-mauve1 dark:text-mauve12',
				false: 'text-mauve12',
			},
			disabled: {true: 'opacity-40 pointer-events-none'},
			invalid: {true: 'border-red7 hover:border-red8'},
			readonly: {true: 'opacity-70 pointer-events-none'},
		},
	},
);

export type CheckboxVariantProps = VariantProps<typeof checkboxStyles>;
