import {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useUnfilteredJobs} from '~/shared/data/job';
import {applySearchFilters} from '~/shared/utils/operations';
import {CloseButton, Command, Dialog, IconButton, Kbd, Separator} from '@job-ish/ui/components';
import {IconListSearch} from '@tabler/icons-react';
import {Virtualizer} from 'virtua';

import type {Job} from '@job-ish/database/types';
import type {ReactNode} from 'react';
import {FederatedSearchJobItem} from './job-item';

export const FederatedSearch = () => {
	const parentRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [searched, setSearched] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const {data: jobs = [], isLoading: isJobsLoading} = useUnfilteredJobs();

	const loading = useMemo(() => isJobsLoading, [isJobsLoading]);

	useEffect(() => {
		const handleEvent = (event: KeyboardEvent) => {
			if (event.metaKey && event.key === 'k') {
				event.preventDefault();
				event.stopImmediatePropagation();
				event.preventDefault();
				setOpen(true);
			}
		};

		document.addEventListener('keydown', handleEvent);
		return () => {
			document.removeEventListener('keydown', handleEvent);
		};
	}, []);

	const filteredData = useMemo(
		() => ({
			jobs: {
				data: applySearchFilters(jobs, ['title', 'company.name', 'location', 'labels', 'notes'], searchTerm),
				label: 'Jobs',
				renderFn: (item: Job) => <FederatedSearchJobItem job={item} key={item.id} setOpen={setOpen} />,
			},
		}),
		[jobs, searchTerm],
	);

	const groupsToRender = useMemo(
		() => Object.entries(filteredData).filter(([, value]) => value.data.length > 0),
		[filteredData],
	);

	const itemCount = useMemo(
		() =>
			groupsToRender.reduce((acc, [, value]) => acc + value.data.length, 0) +
			Object.keys(groupsToRender).length,
		[groupsToRender],
	);

	useEffect(() => {
		if (searchTerm) {
			setSearched(true);
		}
	}, [searchTerm]);

	useEffect(() => {
		if (!open) {
			setSearched(false);
			setSearchTerm('');
		}
	}, [open]);

	return (
		<Dialog onOpenChange={setOpen} open={open}>
			<Dialog.Content className="max-h-full p-0 md:max-h-96" closeTrigger={null} size="xl">
				<Command className="flex flex-col overflow-hidden" shouldFilter={false}>
					<CloseButton className="m-2 ml-auto md:hidden" onPress={() => setOpen(false)} size="sm" />
					<Command.Input
						loading={loading}
						onValueChange={setSearchTerm}
						placeholder="Start typing to search"
						suffix={
							<div className="hidden items-center gap-0.5 md:flex">
								<Kbd className="w-6">⌘</Kbd>
								<Kbd className="w-6">K</Kbd>
							</div>
						}
						value={searchTerm}
					/>
					{searched && <Separator className="w-full" orientation="horizontal" />}
					<Command.List className="flex-grow overflow-y-auto" ref={parentRef}>
						{searchTerm && <Command.Empty>No results found.</Command.Empty>}
						<Virtualizer count={itemCount} itemSize={48} overscan={12} scrollRef={parentRef}>
							{groupsToRender.map(([key, value]) => (
								<Fragment key={key}>
									<Command.Group heading={value.label}>
										{value.data.map(value.renderFn as () => ReactNode)}
									</Command.Group>
									{key !== groupsToRender.at(-1)?.[0] && <Command.Separator />}
								</Fragment>
							))}
						</Virtualizer>
					</Command.List>
				</Command>
			</Dialog.Content>
			<Dialog.Trigger asChild>
				<IconButton circular color="primary" icon={IconListSearch} intent="subtle" />
			</Dialog.Trigger>
		</Dialog>
	);
};
