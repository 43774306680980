import type {JobSearch} from '@job-ish/database/types';
import type {Database} from '@job-ish/database/types/supabase';
import type {SupabaseClient} from '@supabase/supabase-js';

export const fetchJobSearches = async (supabase: SupabaseClient<Database>) =>
	supabase.from('job_searches').select('*').order('id', {ascending: true});

export const fetchActiveJobSearch = async (supabase: SupabaseClient<Database>) => {
	const response = await supabase
		.from('job_searches')
		.select('*')
		.match({is_active: true})
		.maybeSingle<JobSearch>();

	if (!response.data) {
		return supabase
			.from('job_searches')
			.select('*')
			.order('id', {ascending: true})
			.limit(1)
			.maybeSingle<JobSearch>();
	}

	return response;
};
