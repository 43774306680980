import {useSupabase} from '~/shared/hooks/use-supabase';
import {useToast} from '@job-ish/ui/hooks';
import {postData} from '@job-ish/utilities/data';
import {useMutation} from '@tanstack/react-query';
import {useRouter} from 'next/navigation';

import type {User} from '@supabase/supabase-js';

export const useDeleteAccount = () => {
	const {supabase} = useSupabase();
	const router = useRouter();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Account could not be deleted. Please try again.',
		duration: 1500,
		title: 'Account Deletion Failed',
	});

	return useMutation({
		mutationFn: async () => {
			const res = await postData<{user: User}>({
				method: 'DELETE',
				url: '/api/delete-account',
				data: {areYouSure: true},
			});

			if (!res) {
				throw new Error('Failed to delete account');
			}
		},
		onError: () => {
			showErrorToast();
		},
		onSuccess: async () => {
			await supabase.auth.signOut();
			router.push('/auth/login?logged-out=true');
		},
	});
};
