'use client';

import {fetchAccount, fetchUserPreferences} from '~/shared/api/settings';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useToast} from '@job-ish/ui/hooks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import type {Account, UserPreferences} from '@job-ish/database/types';

export const useAccount = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.Account,
		queryFn: async () => {
			const {data} = await fetchAccount(supabase);
			return data;
		},
	});
};

export const useUserPreferences = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.UserPreferences,
		queryFn: async () => {
			const {data} = await fetchUserPreferences(supabase);
			return data;
		},
	});
};

export const useUpsertSettings = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		duration: 1500,
		title: 'Failed to Update Settings',
		description: 'There was an error updating your settings. Please try again.',
	});

	const {show: showSuccessToast} = useToast({
		accent: 'success',
		accentPosition: 'left',
		duration: 1500,
		title: 'Settings Updated',
		description: 'Your settings have been updated.',
	});

	return useMutation({
		mutationFn: async (data: Partial<Account & UserPreferences>) => {
			const [{data: account, data: userPreferences, error}] = await Promise.all([
				supabase
					.from('accounts')
					.upsert(
						{first_name: data.first_name, last_name: data.last_name, occupation: data.occupation},
						{onConflict: 'user_id'},
					)
					.select('*')
					.maybeSingle<Account>(),
				supabase
					.from('user_preferences')
					.upsert({color_mode: data.color_mode}, {onConflict: 'user_id'})
					.select('*')
					.maybeSingle<UserPreferences>(),
			]);
			if (error) throw error;
			return {account, userPreferences};
		},
		onMutate: async (data: Partial<Account & UserPreferences>) => {
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.Account});
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.UserPreferences});
			const previousAccount = queryClient.getQueryData<Account>(QUERY_KEYS.Account);
			const previousUserPreferences = queryClient.getQueryData<UserPreferences>(QUERY_KEYS.UserPreferences);

			const updatedAccount = {...previousAccount, ...data};
			const updatedUserPreferences = {...previousUserPreferences, ...data};

			queryClient.setQueryData(QUERY_KEYS.Account, updatedAccount);
			queryClient.setQueryData(QUERY_KEYS.UserPreferences, updatedUserPreferences);
			return {previousAccount, previousUserPreferences};
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.Account, context?.previousAccount);
			queryClient.setQueryData(QUERY_KEYS.UserPreferences, context?.previousUserPreferences);
			showErrorToast();
		},
		onSuccess: () => {
			showSuccessToast();
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Account});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UserPreferences});
		},
	});
};
