import {useRef} from 'react';
import {useEffectOnce, useMedia} from 'react-use';
import {usePersistedColorMode} from '~/shared/hooks/use-persisted-color-mode';
import LogoDark from '@job-ish/ui/assets/images/job-ish_logo_dark.webp';
import LogoLight from '@job-ish/ui/assets/images/job-ish_logo_light.webp';
import {IconButton, Sheet} from '@job-ish/ui/components';
import {IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand} from '@tabler/icons-react';
import clsx from 'clsx';
import NextImage from 'next/image';

import {NavigationList} from './navigation-list';

type SidebarProps = {
	open?: boolean;
	onOpenChange: (open: boolean) => void;
};

export const Sidebar = ({open, onOpenChange}: SidebarProps) => {
	const modal = useMedia('(max-width: 768px)', false);
	const triggerRef = useRef<HTMLButtonElement>(null);
	const colorMode = usePersistedColorMode();

	useEffectOnce(() => {
		if (open !== undefined && triggerRef.current) {
			triggerRef.current.focus();
		}
	});

	return (
		<Sheet modal={modal} onOpenChange={onOpenChange} open={open}>
			{!open && (
				<div className="hidden flex-col bg-mauve2 px-1.5 py-2.5 md:flex">
					<IconButton
						className="mx-auto mb-8 h-11 w-14"
						icon={IconLayoutSidebarLeftExpand}
						intent="ghost"
						onPress={() => onOpenChange(true)}
					/>
					<div className="py-1">
						<NavigationList iconsOnly />
					</div>
				</div>
			)}
			<div className={clsx(open && 'absolute w-56 min-w-[14rem] md:relative')}>
				<Sheet.Content
					blur={modal}
					className="w-56 min-w-[14rem] px-1.5 ring-0"
					closeTrigger={
						<IconButton className="h-11 w-14" icon={IconLayoutSidebarLeftCollapse} intent="ghost" />
					}
					onEscapeKeyDown={event => !modal && event.preventDefault()}
					onInteractOutside={event => !modal && event.preventDefault()}
				>
					<Sheet.Title className="flex items-center px-2">
						<div className="h-[54px]">
							<a href="https://job-ish.com" rel="noopener noreferrer" target="_blank">
								<NextImage
									alt="job-ish"
									className="absolute top-1"
									height={50}
									loading="eager"
									src={colorMode === 'dark' ? LogoDark : LogoLight}
									width={100}
								/>
							</a>
						</div>
					</Sheet.Title>
					<div className="px-1 py-1">
						<NavigationList />
					</div>
				</Sheet.Content>
			</div>
		</Sheet>
	);
};
