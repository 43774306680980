import type {JobSearchStatus} from '~/shared/constants/job-search';
import {JOB_SEARCH_STATUS} from '~/shared/constants/job-search';
import {z} from 'zod';

import type {JobSearch} from '@job-ish/database/types';

export const upsertJobSearchSchema = z.object({
	name: z.string({required_error: 'Name is required', invalid_type_error: 'Name is required'}).min(1),
	status: z.enum(Object.values(JOB_SEARCH_STATUS) as [JobSearchStatus, ...JobSearchStatus[]]),
});

export type UpsertJobSearchFormSchema = z.infer<typeof upsertJobSearchSchema>;

export const getDefaultUpsertJobSearchFormValues = (jobSearch?: JobSearch) => ({
	name: jobSearch?.name ?? '',
	status: (jobSearch?.status as UpsertJobSearchFormSchema['status']) ?? 'active',
	is_active: !!jobSearch?.is_active,
});
