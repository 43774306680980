import {useCallback} from 'react';
import {CompanyLogo} from '~/shared/components/company-logo';
import type {JobSheetProps} from '~/shared/components/sheets/job';
import {JobSheet} from '~/shared/components/sheets/job';
import {jobStatuses, jobStatusGroups} from '~/shared/constants/job';
import {Badge, Command} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import dayjs from 'dayjs';

import type {Job} from '@job-ish/database/types';
import type {BadgeProps} from '@job-ish/ui/components';

export type FederatedSearchJobItemProps = {
	job: Job;
	setOpen: (open: boolean) => void;
};

export const FederatedSearchJobItem = ({job, setOpen}: FederatedSearchJobItemProps) => {
	const {show: showDialog} = useModal();

	const getJobStatus = useCallback((job: Job) => jobStatuses[job.status], []);
	const getJobStatusGroup = useCallback(
		(job: Job) => jobStatusGroups[getJobStatus(job)?.group],
		[getJobStatus],
	);

	return (
		<Command.Item
			className="h-12"
			key={job.id}
			onSelect={() => {
				setOpen(false);
				showDialog<JobSheetProps>(JobSheet, {job});
			}}
		>
			<div className="flex w-full items-center justify-between">
				<div className="flex flex-col">
					<div className="flex items-center gap-1">
						<CompanyLogo company={job.company} />
						<div className="truncate">{job.title}</div>
					</div>
					<div className="truncate text-xs text-mauve11">{job.company?.name ?? 'Anonymous'}</div>
				</div>
				<div className="flex min-w-fit flex-col items-end">
					<Badge color={getJobStatusGroup(job)?.color as BadgeProps['color']} size="xs">
						{getJobStatus(job)?.label}
					</Badge>
					{job.applied_at && (
						<div className="text-[10px] text-mauve10">
							Applied on {dayjs(job.applied_at).format('MMM D, YYYY [at] h:mm A')}
						</div>
					)}
				</div>
			</div>
		</Command.Item>
	);
};
