import {createElement, forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {useBreadcrumbItem, useBreadcrumbs} from '@react-aria/breadcrumbs';
import {useLink} from '@react-aria/link';
import {mergeProps} from '@react-aria/utils';
import {IconChevronRight} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {AriaBreadcrumbItemProps, AriaBreadcrumbsProps} from '@react-aria/breadcrumbs';
import type {
	ComponentPropsWithoutRef,
	ElementType,
	HTMLAttributes,
	PropsWithChildren,
	ReactNode,
} from 'react';
import Anchor from '../anchor';
import {anchorStyles} from '../anchor/styles';
import {navLinksItemStyles, navLinksStyles} from './styles';
import type {Icon} from '../../types';
import type {AnchorVariantProps} from '../anchor/styles';
import type {NavLinksItemVariantProps, NavLinksVariantProps} from './styles';

export type NavLinksItemProps = PropsWithChildren<
	Omit<AriaBreadcrumbItemProps, 'isDisabled' | 'isCurrent' | 'as' | 'children'> &
		HTMLAttributes<HTMLLIElement> &
		NavLinksItemVariantProps &
		AnchorVariantProps & {
			current?: boolean;
			disabled?: boolean;
			as?: ElementType;
			icon?: Icon;
			centered?: boolean;
		}
>;
const NavLinksItem = forwardRef<HTMLElement, NavLinksItemProps>(
	(
		{
			as: Component = Anchor,
			current,
			centered,
			rel,
			target,
			href,
			icon,
			disabled,
			className,
			children,
			...props
		},
		forwardedRef,
	) => {
		const ref = useRef<HTMLLIElement>(null);
		const {itemProps} = useBreadcrumbItem(
			{...props, isCurrent: current, isDisabled: disabled, children: undefined},
			ref,
		);
		const {linkProps} = useLink({...props, isDisabled: disabled, elementType: Component as string}, ref);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLElement);

		return (
			<li {...mergeProps(props, itemProps)} className="w-full">
				<Component
					{...linkProps}
					className={twMerge(
						anchorStyles({disabled}),
						navLinksItemStyles({current, disabled, centered}),
						className,
					)}
					href={href}
					ref={ref}
					rel={rel}
					target={target}
				>
					{icon && createElement(icon, {className: 'h-5 w-5 text-mauve11'})}
					{children}
				</Component>
			</li>
		);
	},
);

export type NavLinksSubProps = Omit<NavLinksItemProps, 'as' | 'current'> & {
	defaultExpanded?: boolean;
	label?: ReactNode;
};
const NavLinksSub = forwardRef<HTMLButtonElement, NavLinksSubProps>(
	({label, children, className, defaultExpanded, ...props}, forwardedRef) => {
		const [expanded, setExpanded] = useState(defaultExpanded);
		const ref = useRef<HTMLButtonElement>(null);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLButtonElement);

		return (
			<>
				<NavLinksItem
					{...props}
					as="div"
					className={twMerge('cursor-pointer', className)}
					current={false}
					onPress={() => setExpanded(!expanded)}
					ref={ref}
				>
					<span className="grow">{label}</span>
					<IconChevronRight
						className={twMerge(
							'h-4 w-4 transition-all duration-150 ease-in-out',
							expanded ? 'rotate-90' : '',
						)}
					/>
				</NavLinksItem>
				{expanded && <div className="w-full pl-5 animate-in fade-in slide-in-from-top-2">{children}</div>}
			</>
		);
	},
);

export type NavLinksProps = ComponentPropsWithoutRef<'div'> & AriaBreadcrumbsProps & NavLinksVariantProps;
const NavLinks = assign(
	forwardRef<HTMLDivElement, NavLinksProps>(({children, className, ...props}, forwardedRef) => {
		const {navProps} = useBreadcrumbs(props);

		return (
			<nav
				{...navProps}
				aria-label="NavLinks"
				className={twMerge(navLinksStyles(), className)}
				ref={forwardedRef}
			>
				<ol className="flex flex-col">{children}</ol>
			</nav>
		);
	}),
	{Item: NavLinksItem, Sub: NavLinksSub},
);

NavLinks.displayName = 'NavLinks';
NavLinks.Item.displayName = 'NavLinks.Item';
NavLinks.Sub.displayName = 'NavLinks.Sub';

export default NavLinks;

export * from './styles';
