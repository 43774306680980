'use client';

import {COLOR_MODE_COOKIE} from '~/shared/constants/cookies';
import {useUserPreferences} from '~/shared/data/settings';
import {useColorMode} from '@job-ish/ui/hooks';

export const usePersistedColorMode = () => {
	const {data: userPreferences} = useUserPreferences();
	const colorMode = useColorMode({
		initialValue: userPreferences?.color_mode ?? undefined,
		cookieName: COLOR_MODE_COOKIE,
	});

	return colorMode;
};
