import {useAccount} from '~/shared/data/settings';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {Avatar} from '@job-ish/ui/components';
import {IconUser} from '@tabler/icons-react';

import type {AvatarProps} from '@job-ish/ui/components';

type UserAvatarProps = AvatarProps;

export const UserAvatar = (props: UserAvatarProps) => {
	const {user} = useSupabase();
	const {data: account} = useAccount();

	return (
		<Avatar {...props}>
			<Avatar.Fallback>
				{account?.first_name?.charAt(0).toLocaleUpperCase() || user?.email?.charAt(0).toLocaleUpperCase() || (
					<IconUser className="h-5 w-5" />
				)}
			</Avatar.Fallback>
		</Avatar>
	);
};
