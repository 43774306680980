import {forwardRef, useContext, useId} from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import {IconCheck, IconMinus} from '@tabler/icons-react';
import {twMerge} from 'tailwind-merge';

import {FormControlContext} from '../form-control';
import {checkboxStyles} from './styles';
import type {CheckboxVariantProps} from './styles';

export type CheckedState = boolean | 'indeterminate';
export type CheckboxProps = Omit<CheckboxPrimitive.CheckboxProps, 'children'> &
	Omit<CheckboxVariantProps, 'invalid'>;
const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
	({readonly, checked, disabled, className, id, ...props}, forwardedRef) => {
		const formControlProps = useContext(FormControlContext);
		const defaultId = useId();

		return (
			<CheckboxPrimitive.Root
				{...props}
				aria-label={props['aria-label'] || 'Toggle checkbox'}
				aria-labelledby={formControlProps.id}
				checked={checked}
				className={twMerge(
					checkboxStyles({
						checked,
						disabled,
						readonly,
						invalid: formControlProps.invalid,
					}),
					className,
				)}
				disabled={disabled}
				id={id || `${formControlProps.id || defaultId}-field`}
				ref={forwardedRef}
			>
				<CheckboxPrimitive.Indicator>
					{checked === 'indeterminate' && <IconMinus className="h-4 w-4" />}
					{checked === true && <IconCheck className="h-4 w-4" />}
				</CheckboxPrimitive.Indicator>
			</CheckboxPrimitive.Root>
		);
	},
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;

export * from './styles';
