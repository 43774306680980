import {useCallback, useState} from 'react';
import type {DeleteAccountAlertProps} from '~/shared/components/alerts/delete-account';
import {DeleteAccountAlert} from '~/shared/components/alerts/delete-account';
import {UserAvatar} from '~/shared/components/user-avatar';
import {PATHS} from '~/shared/constants/paths';
import {useSubscription} from '~/shared/data/subscription';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {Anchor, Badge, DropdownMenu, IconButton, Overlay, TextButton, Tooltip} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {
	IconCreditCard,
	IconLicense,
	IconLifebuoy,
	IconListDetails,
	IconLogout,
	IconSettings,
	IconShieldLock,
} from '@tabler/icons-react';
import NextLink from 'next/link';
import {usePathname} from 'next/navigation';

export const UserDropdown = () => {
	const {user, supabase} = useSupabase();
	const [loggingOut, setLoggingOut] = useState(false);
	const {data: subscription} = useSubscription();
	const pathname = usePathname();
	const {show: showDialog} = useModal();

	const handleLogout = useCallback(
		async (event: Event) => {
			event.preventDefault();
			setLoggingOut(true);
			const {error} = await supabase.auth.signOut();
			if (error) setLoggingOut(false);
		},
		[supabase.auth],
	);

	return (
		<DropdownMenu>
			<DropdownMenu.Trigger asChild>
				<IconButton
					aria-label="Open User Dropdown Menu"
					circular
					className="shrink-0 focus-visible:outline-1"
					color="neutral"
					icon={() => <UserAvatar className="h-5 w-5 shrink-0 bg-transparent" />}
					intent="subtle"
				/>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content align="end" className="z-10 w-72">
				{loggingOut && <Overlay loading />}
				<DropdownMenu.Label>
					<div className="flex flex-col gap-0.5">
						<div className="truncate">Signed in as {user?.email}</div>
						{subscription && (
							<div className="flex items-center gap-1">
								<span>Current Plan:</span>
								{subscription?.status === 'active' ? (
									<Tooltip>
										<Tooltip.Trigger>
											<Badge color="success" size="sm">
												Active
											</Badge>
										</Tooltip.Trigger>
										<Tooltip.Content align="end" className="z-10" side="bottom">
											<div className="w-64">
												Your subscription is active. You can cancel your subscription at any time in Account
												Settings.
											</div>
										</Tooltip.Content>
									</Tooltip>
								) : (
									<Tooltip>
										<Tooltip.Trigger>
											<Badge as={NextLink} color="danger" href={PATHS.Subscribe} size="sm">
												Inactive
											</Badge>
										</Tooltip.Trigger>
										<Tooltip.Content align="end" className="z-10" side="bottom">
											<div className="w-64">
												Your subscription is not active. You can activate your subscription{' '}
												<Anchor as={NextLink} className="inline" href={PATHS.Subscribe}>
													on the subscribe page
												</Anchor>{' '}
												at any time to gain complete access to job-ish.
											</div>
										</Tooltip.Content>
									</Tooltip>
								)}
							</div>
						)}
						<div className="flex items-center gap-2">
							{subscription?.status !== 'active' && (
								<TextButton
									color="danger"
									onPress={() => showDialog<DeleteAccountAlertProps>(DeleteAccountAlert)}
									size="sm"
								>
									Delete Account
								</TextButton>
							)}
						</div>
					</div>
				</DropdownMenu.Label>
				{subscription?.status !== 'active' && (
					<>
						<DropdownMenu.Separator />
						<DropdownMenu.Group>
							{pathname === PATHS.Subscribe ? (
								<NextLink href={PATHS.JobBoard}>
									<DropdownMenu.Item icon={IconListDetails}>View Job Board</DropdownMenu.Item>
								</NextLink>
							) : (
								<NextLink href={PATHS.Subscribe}>
									<DropdownMenu.Item icon={IconCreditCard}>Upgrade to Premium</DropdownMenu.Item>
								</NextLink>
							)}
						</DropdownMenu.Group>
					</>
				)}
				<DropdownMenu.Separator />
				<DropdownMenu.Group>
					<a href="mailto:help@job-ish.com" rel="noopener noreferrer" target="_blank">
						<DropdownMenu.Item icon={IconLifebuoy}>Support</DropdownMenu.Item>
					</a>
					<a href="https://job-ish.com/terms" rel="noopener noreferrer" target="_blank">
						<DropdownMenu.Item icon={IconLicense}>Terms of Use</DropdownMenu.Item>
					</a>
					<a href="https://job-ish.com/privacy-policy" rel="noopener noreferrer" target="_blank">
						<DropdownMenu.Item icon={IconShieldLock}>Privacy Policy</DropdownMenu.Item>
					</a>
				</DropdownMenu.Group>
				<DropdownMenu.Separator />
				<DropdownMenu.Group>
					{subscription?.status === 'active' && (
						<NextLink href="/settings">
							<DropdownMenu.Item icon={IconSettings}>Account Settings</DropdownMenu.Item>
						</NextLink>
					)}
					<DropdownMenu.Item icon={IconLogout} onSelect={handleLogout}>
						Log Out
					</DropdownMenu.Item>
				</DropdownMenu.Group>
			</DropdownMenu.Content>
		</DropdownMenu>
	);
};
